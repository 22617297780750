<template>
  <div class="ysContent">
    <!-- <div class="ys">
      <table
        border="1"
        width="100%"
        cellpadding="0"
        cellspacing="0"
        bordercolorlight="#FFFFFF"
        bordercolordark="#FFFFFF"
        bgcolor="#FFFFFF"
        style="table-layout: fixed"
      >
        <tbody>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a
                target="_parent"
                href="https://1733888.772635.com/#1733888.com"
                class="noline"
                ><font color="#000000"> 【软硬不吃】</font
                ><span
                  style="
                    background: #000000;
                    color: #ffff00;
                    border-radius: 7px;
                  "
                  >【神奇三码】</span
                ><font color="#000000">已更新！</font></a
              ><b>软硬不吃</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>

              <a href="https://www.5288815.com" target="_blank"
                ><font color="#000000">【德彩网投】【一肖一码】已更新！</font
                ><b>德彩网投</b></a
              >
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/01.html" class="noline">
                <font color="#000000">【天下有始】【男女中特】已更新！</font></a
              ><b>天下有始</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/02.html" class="noline">
                <font color="#000000">【治国安邦】【单双中特】已更新！</font></a
              ><b>治国安邦</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/03.html" class="noline">
                <font color="#000000">【一唱三起】【三头中特】已更新！</font></a
              ><b>一唱三起</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/04.html" class="noline">
                <font color="#000000">【快乐天堂】【家野中特】已更新！</font></a
              ><b>快乐天堂</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/05.html" class="noline">
                <font color="#000000">【醉不成欢】【大小中特】已更新！</font></a
              ><b>醉不成欢</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/06.html" class="noline">
                <font color="#000000">【倾城绝爱】【平特一肖】已更新！</font></a
              ><b>倾城绝爱</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a
                target="_blank"
                href="https://1733888.426tk.com/#1733888.com"
                class="noline"
              >
                <font color="#000000">【美人鱼网】【五行特肖】已更新！</font></a
              ><b>美人鱼网</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/07.html" class="noline">
                <font color="#000000">【口快心直】【吉美凶丑】已更新！</font></a
              ><b>口快心直</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/08.html" class="noline">
                <font color="#000000">【道听途说】【两波中特】已更新！</font></a
              ><b>道听途说</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a
                target="_blank"
                href="https://1733888.412tk.com/#1733888.com"
                class="noline"
              >
                <font color="#000000">【白姐论坛】【一码中特】已更新！</font></a
              ><b>白姐论坛</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/09.html" class="noline">
                <font color="#000000">【身材娇小】【三季中特】已更新！</font></a
              ><b>身材娇小</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/10.html" class="noline">
                <font color="#000000">【水银泻地】【六肖中特】已更新！</font></a
              ><b>水银泻地</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/11.html" class="noline">
                <font color="#000000">【地板效应】【左右中特】已更新！</font></a
              ><b>地板效应</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a
                target="_blank"
                href="https://1733888.440tk.com/#1733888.com"
                class="noline"
              >
                <font color="#000000">【金手指网】【精准三肖】已更新！</font></a
              ><b>金手指网</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a
                target="_blank"
                href="https://1733888.423tk.com/#1733888.com"
                class="noline"
              >
                <font color="#000000">【铁算盘网】【两肖三码】已更新！</font></a
              ><b>铁算盘网</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a
                target="_blank"
                href="https://1733888.354tk.com/#1733888.com"
                class="noline"
              >
                <font color="#000000">【澳彩】【复式三肖】已更新！</font></a
              ><b>澳彩</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/12.html" class="noline">
                <font color="#000000">【东北一家】【阴阳中特】已更新！</font></a
              ><b>东北一家</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/13.html" class="noline">
                <font color="#000000">【鱼水相欢】【绝杀两尾】已更新！</font></a
              ><b>鱼水相欢</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/14.html" class="noline">
                <font color="#000000"
                  >【天地唯心】<span>【复式三尾】</span>已更新！</font
                ></a
              ><b>天地唯心</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/15.html" class="noline">
                <font color="#000000">【大显身手】【五尾中特】已更新！</font></a
              ><b>大显身手</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/16.html" class="noline">
                <font color="#000000">【我的将军】【琴棋书画】已更新！</font></a
              ><b>我的将军</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a href="https://www.5288815.com" target="_blank"
                ><font color="#000000">【德彩网投】【三肖三码】已更新！</font
                ><b>德彩网投</b></a
              >
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a
                target="_blank"
                href="https://1733888.435tk.com/#1733888.com"
                class="noline"
              >
                <font color="#000000">【观音救世】【三肖四码】已更新！</font></a
              ><b>观音救世</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/17.html" class="noline">
                <font color="#000000">【实事求是】【四段中特】已更新！</font></a
              ><b>实事求是</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/18.html" class="noline">
                <font color="#000000">【澳门彩料】【平特一尾】已更新！</font></a
              ><b>澳门彩料</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/19.html" class="noline">
                <font color="#000000">【八卦神奇】【八肖中特】已更新！</font></a
              ><b>八卦神奇</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/20.html" class="noline">
                <font color="#000000">【绿豆宝贝】【三行中特】已更新！</font></a
              ><b>绿豆宝贝</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/21.html" class="noline">
                <font color="#000000">【星空之列】【东南西北】已更新！</font></a
              ><b>星空之列</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/22.html" class="noline">
                <font color="#000000">【赢钱精品】【三字平特】已更新！</font></a
              ><b>赢钱精品</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/23.html" class="noline">
                <font color="#000000">【波比小姐】【前后中特】已更新！</font></a
              ><b>波比小姐</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/24.html" class="noline">
                <font color="#000000">【浦南上世】【绝杀一肖】已更新！</font></a
              ><b>浦南上世</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/25.html" class="noline">
                <font color="#000000">【顶级麻花】【平特一码】已更新！</font></a
              ><b>顶级麻花</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/26.html" class="noline">
                <font color="#000000">【期期大赚】【七尾中特】已更新！</font></a
              ><b>期期大赚</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/27.html" class="noline">
                <font color="#000000">【人生巅峰】【合数中特】已更新！</font></a
              ><b>人生巅峰</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/28.html" class="noline">
                <font color="#000000">【刻上天衢】【绝杀半波】已更新！</font></a
              ><b>刻上天衢</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/29.html" class="noline">
                <font color="#000000">【六界独尊】【四门特围】已更新！</font></a
              ><b>六界独尊</b>
            </td>
          </tr>
          <tr>
            <td class="f11 black l150 tb2">
              <div class="txt">111期</div>
              <a target="_blank" href="/bbs/30.html" class="noline">
                <font color="#000000"
                  >【发展空间】<span>【绝杀一尾】</span>已更新！</font
                ></a
              ><b>发展空间</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div> -->
    <div v-html="htmlObj.cpgxHtml"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["htmlObj"]),
  },
  name: "bankaccount",
  data() {
    return {
      contentData: [],
      html:``,
    };
  },
  mounted() {
    console.log(JSON.stringify(this.contentData));
    // 开奖列表
    // this.getkjlbData();
  },
  methods: {
    async getkjlbData() {
      this.contentData = await this.$api.home.getkjlbData();
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  display: table;
  width: 100%;
  text-align: center;
  // border-collapse: collapse;
  // border-spacing: 0;
  box-sizing: border-box;
  text-indent: initial;
}
table {
  // border-collapse: collapse;
  // border-spacing: 0;
}
.tb2 {
  width: 100%;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
a.black,
.black,
.black a {
  color: #333;
}
.l150 {
  line-height: 150%;
}
.f11,
.f11 a {
  font-size: 11pt;
}
.tb2 {
  text-align: left;
}
table td {
  border: 1px solid #e5e5e5;
  word-break: break-all;
}
.txt {
  padding-left: 15px;
  padding-right: 23px;
  background-color: #d64343;
  border-radius: 5px;
  color: #ffffff;
  // float: left;
  margin: 0px -12px;
  font-family: 微软雅黑;
  font-size: 14px;
  background: url("@/assets/img/common/gsh.png") left center no-repeat;
}
.txt {
  padding-right: 13px;
}
.noline a,
a.noline {
  text-decoration: none;
}
a.black,
.black,
.black a {
  color: #333;
}
.f11,
.f11 a {
  font-size: 11pt;
}
.ys span {
  background-color: #ffff00;
  color: #ff0000;
}
.tb2 b {
  float: right;
  margin-right: 2px;
  font-weight: 400;
}
</style>
