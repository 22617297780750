import axios from '../base.js';
import Util from '@/utils/common.js';

function sxdzb(no) {
  if (no == 1) {
    return "鼠"
  } else if (no == 2) {
    return "牛"
  } else if (no == 3) {
    return "虎"
  } else if (no == 4) {
    return "兔"
  } else if (no == 5) {
    return "龙"
  } else if (no == 6) {
    return "蛇"
  } else if (no == 7) {
    return "马"
  } else if (no == 8) {
    return "羊"
  } else if (no == 9) {
    return "猴"
  } else if (no == 10) {
    return "鸡"
  } else if (no == 11) {
    return "狗"
  } else if (no == 12) {
    return "猪"
  } else {
    return ""
  }
}
export default class home {
  //获取香港幸运彩开奖结果数据
  static async getkjjgData(params = {
    pageNum: 1,
    pageSize: 1000,
    lotteryType: 3,
    lotteryStatus: 3,
  }) {
    if (window.globalConfig.isLocalData) { //是否使用本地数据
      let res = await axios.get(`/data/common/开奖结果.json`);
      return res.data.data;
    } else { //使用接口数据
      let res = await axios.get(`/hmglUrl/kj2130/h5/lottery/search` + Util.getParams(params));
      console.log(res.data.data.recordList);
      return res.data.data.recordList.map(e => {
        var parts = e.lotteryTime.split(/年|月|日/);
        console.log(parts);
        // debugger;
        // e.address= "澳门"; //地址
        e.nextPeriod = Number(e.period) + 1; //下一期
        e.nextPeriodMonth = parts[1]; //下一期月份
        e.nextPeriodDay = parts[2]; //下一期日
        e.nextPeriodWeek = e.zhouji.replace("星期", ""); //下一期周几
        e.nextPeriodTime = e.shifen; //下一期时间
        e.date = `${parts[0]}-${parts[1]}-${parts[2]} ${e.shifen}`
        e.numberList = e.numberList.map(ele => {
          return {
            num: ele.number, //号码
            shuxiang: ele.shengXiao, //属相
            wuxing: ele.wuXing, //五行
            ...ele,
          }
        })
        return e;
      }).sort((a, b) => {
        // 转换日期格式从 "YYYY年MM月DD日" 到 "YYYY/MM/DD"
        let format = (dateString) => {
          return dateString.replace(/(\d+)年(\d+)月(\d+)日/, '$1-$2-$3');
        };

        // 将日期字符串转换为 Date 对象
        let dateA = new Date(format(a.lotteryTime));
        let dateB = new Date(format(b.lotteryTime));
        // 返回比较结果，进行降序排序
        return dateB - dateA;
      });
    }
  }


  //获取香港幸运彩开奖结果数据
  static async getXYC_ZUIJIN_kjjgData(params = {
    pageNum: 1,
    pageSize: 1000,
    lotteryType: 3,
    lotteryStatus: 3,
  }) {
    if (window.globalConfig.isLocalData) { //是否使用本地数据
      let res = await axios.get(`/data/common/开奖结果.json`);
      return res.data.data;
    } else { //使用接口数据
      // let res = await axios.get(`/hmglUrl/kj2130/h5/lottery/search` + Util.getParams(params));
      let res = await axios.post(`/apiUrl/api/Index/getJson?fileName=首页号码`);
      res.data = JSON.parse(res.data.data.fileValue)
      let recordList = res.data.data.recordList;
      let wkjItem = recordList.filter(a => a.lotteryStatus == 0).sort((a, b) => {
        // 转换日期格式从 "YYYY年MM月DD日" 到 "YYYY/MM/DD"
        let format = (dateString) => {
          return dateString.replace(/(\d+)年(\d+)月(\d+)日/, '$1-$2-$3');
        };

        // 将日期字符串转换为 Date 对象
        let dateA = new Date(format(a.lotteryTime));
        let dateB = new Date(format(b.lotteryTime));
        // 返回比较结果，进行降序排序
        return dateA - dateB;
      })[0];
      let ykjItem = recordList.filter(a => a.lotteryStatus != 0).sort((a, b) => {
        // 转换日期格式从 "YYYY年MM月DD日" 到 "YYYY/MM/DD"
        let format = (dateString) => {
          return dateString.replace(/(\d+)年(\d+)月(\d+)日/, '$1-$2-$3');
        };

        // 将日期字符串转换为 Date 对象
        let dateA = new Date(format(a.lotteryTime));
        let dateB = new Date(format(b.lotteryTime));
        // 返回比较结果，进行降序排序
        return dateB - dateA;
      })[0];
      //防止没有数据的undefined
      if (!ykjItem) {
        ykjItem = {}
      }
      if (!wkjItem) {
        wkjItem = {}
      }
      let resDB = {}
      try {
        resDB = {
          "drawCount": "0",
          "totalCount": 1,
          "preDrawIssue": ykjItem.period || "",
          "drawIssue": ykjItem.nextPeriod || "",
          "sumTotal": 197,
          "chineseZodiac": "3,6,5,10,4,1,8",
          "color": "3,3,3,2,2,2,1",
          "preDrawCode": "03,36,25,32,38,17,46",
          "preDrawTime": "2024-04-25 21:34:00",
          "drawTime": this.formatTime(ykjItem.date),
          "drawUpTime": "2024-04-25 21:35:06",
          "serverTime": "2024-04-25 23:57:24",
          "noopen": "Y",
          "lottoName": null,
          "lotCode": "gflhc",

        }
      } catch (error) {
        console.log(error);
        console.log(wkjItem);
        resDB = {
          "drawCount": "0",
          "totalCount": 1,
          "preDrawIssue": "",
          "drawIssue": "",
          "sumTotal": 197,
          "chineseZodiac": "3,6,5,10,4,1,8",
          "color": "3,3,3,2,2,2,1",
          "preDrawCode": "03,36,25,32,38,17,46",
          "preDrawTime": "2024-04-25 21:34:00",
          "drawTime": "",
          "drawUpTime": "2024-04-25 21:35:06",
          "serverTime": "2024-04-25 23:57:24",
          "noopen": "Y",
          "lottoName": null,
          "lotCode": "gflhc",
        }
      }
      ykjItem.numberList = ykjItem.numberList.map(ele => {

        let noNone = false;
        noNone = this.getIsShowTime(ykjItem.lotteryTime)
        return {
          num: ele.number, //号码
          shuxiang: ele.shengXiao, //属相
          wuxing: ele.wuXing, //五行
          ...ele,
          noNone: true,
        }
      })
      return [{
        resJKDb: resDB,
        numberList: ykjItem.numberList,
        nextPeriodTime: ykjItem.hourMin,
        // nextPeriodWeek: "星期" + ykjItem.week,
      }]
    }
  }


  // 香港六合彩  post方式  https://vv1688.vip/caiji/speedLot.php?reqtype=gflhc&ishistory=Y&date=

  // 澳门六合彩：post方式 https://vv1688.vip/caiji/speedLot.php?reqtype=jslhc&ishistory=Y&date=

  //获取澳门香港开奖结果数据
  static async getamxgkjjgData(type) {
    if (window.globalConfig.isLocalData) { //是否使用本地数据
      let res = await axios.get(`/data/common/开奖结果.json`);
      return res.data.data;
    } else { //使用接口数据


      let dqsj = this.getCurrentFormattedDate();
      let res = await axios.post(`https://vv1688.vip/caiji/speedLot.php?reqtype=${type}&ishistory=Y&date=2022-09-06%20-%20` + dqsj);
      return res.data.result.data.map(e => {
        // 使用 split 方法拆分字符串
        var parts = e.preDrawTime.split(/-| /);
        console.log(parts);
        // 创建一个 Date 对象并解析日期时间字符串
        var date = new Date(e.preDrawTime);

        // 获取对应的周几（0 表示周日，1 表示周一，以此类推）
        var dayOfWeek = date.getDay();

        // 将数字转换为对应的周几字符串
        var weekdays = ['日', '一', '二', '三', '四', '五', '六'];
        var dayString = weekdays[dayOfWeek];
        // debugger;
        // e.address= "澳门"; //地址
        e.period = e.preDrawIssue
        e.nextPeriod = Number(e.preDrawIssue) + 1; //下一期
        e.nextPeriodMonth = parts[1]; //下一期月份
        e.nextPeriodDay = parts[2]; //下一期日
        e.nextPeriodWeek = dayString; //下一期周几
        e.nextPeriodTime = parts[3]; //下一期时间
        e.date = e.preDrawTime
        e.numberList = e.preDrawCode.split(",").map((ele, i) => {
          let shuxiang = "";
          try {
            shuxiang = e.czAndFe.split(',')[i]
          } catch (error) {
            console.log(error);
          }
          return {
            num: ele, //号码
            shuxiang: sxdzb(shuxiang) || "", //属相
            wuxing: "", //五行
          }
        })
        return e;
      });
    }
  }
  static async getamxgkjjgDatanew(page, type = "2") {
    if (window.globalConfig.isLocalData) { //是否使用本地数据
      let res = await axios.get(`/data/common/开奖结果.json`);
      return res.data.data;
    } else { //使用接口数据


      let dqsj = this.getCurrentFormattedDate();
      let res = await axios.get(`https://api2.118tapi.com:8443/api/v1/liuhe/history?year=2024&lotteryType=${type}&page=${page}&sort=desc`);
      return res.data.data.data.map(e => {
        e.date = e.lotteryTime;
        e.period = e.issue
        e.numberList = e.number_attr.map((ele, i) => {
          return {
            num: ele.number, //号码
            shuxiang: ele.shengXiao, //属相
            wuxing: ele.wuXing, //五行
          }
        })
        return e;
      });
    }
  }





  static async get_zuijin_amxgkjjgData(type) {
    if (window.globalConfig.isLocalData) { //是否使用本地数据
      let res = await axios.get(`/data/common/开奖结果.json`);
      return res.data.data;
    } else { //使用接口数据


      let dqsj = this.getCurrentFormattedDate();
      // let res = await axios.post(`https://vv1688.vip/caiji/speedLot.php?reqtype=${type}`);
      // let res = await axios.post(`https://vv1688.vip/caiji/speedLot.php?reqtype=${type}&ishistory=Y&date=2022-09-06%20-%20`+dqsj);
      let res = await axios.post(`https://vv1688.vip/caiji/speedLot.php?reqtype=${type}`);

      let resJKDb = res.data.result.data;

      let resDB = [{
        resJKDb,
        numberList: []
      }];



      // // 创建一个 Date 对象并解析日期时间字符串
      // var date = new Date(e.drawTime);
      // // 获取对应的周几（0 表示周日，1 表示周一，以此类推）
      // var dayOfWeek = date.getDay();
      // // 将数字转换为对应的周几字符串
      // var weekdays = ['日', '一', '二', '三', '四', '五', '六'];
      // var dayString = weekdays[dayOfWeek];

      resDB[0].numberList = resJKDb.preDrawCode.split(",").map((ele, i) => {
        let shuxiang = "";
        let noNone = false; //是否到了开奖时间
        console.log(resJKDb); //drawTime
        try {
          shuxiang = resJKDb.chineseZodiac.split(',')[i]
          noNone = this.getIsShowTime(resJKDb.drawTime)
        } catch (error) {
          console.log(error);
        }

        return {
          num: ele, //号码
          shuxiang: sxdzb(shuxiang) || "", //属相
          wuxing: "", //五行
          noNone: true,
        }
      })
      return resDB;
      // return res.data.result.data.map(e => {
      //   // 使用 split 方法拆分字符串
      //   var parts = e.preDrawTime.split(/-| /);
      //   console.log(parts);
      //   // 创建一个 Date 对象并解析日期时间字符串
      //   var date = new Date(e.preDrawTime);

      //   // 获取对应的周几（0 表示周日，1 表示周一，以此类推）
      //   var dayOfWeek = date.getDay();

      //   // 将数字转换为对应的周几字符串
      //   var weekdays = ['日', '一', '二', '三', '四', '五', '六'];
      //   var dayString = weekdays[dayOfWeek];
      //   // debugger;
      //   // e.address= "澳门"; //地址
      //   e.period = e.preDrawIssue
      //   e.nextPeriod = Number(e.preDrawIssue) + 1; //下一期
      //   e.nextPeriodMonth = parts[1]; //下一期月份
      //   e.nextPeriodDay = parts[2]; //下一期日
      //   e.nextPeriodWeek = dayString; //下一期周几
      //   e.nextPeriodTime = parts[3]; //下一期时间
      //   e.date=e.preDrawTime

      //   return e;
      // });
    }
  }
  static async get_zuijin_amxgkjjgDatanew(type = "2") {
    if (window.globalConfig.isLocalData) { //是否使用本地数据
      let res = await axios.get(`/data/common/开奖结果.json`);
      return res.data.data;
    } else { //使用接口数据
      let res = await axios.get(`https://api2.118tapi.com:8443/api/v1/liuhe/history?year=2024&lotteryType=${type}&page=1&sort=desc`);
      let res2 = await axios.get(`https://api2.118tapi.com:8443/api/v1/liuhe/next`);
      console.log(res);
      let resJKDb = res.data.data.data;

      let resDB = [{
        resJKDb,
        numberList: []
      }];



      // // 创建一个 Date 对象并解析日期时间字符串
      // var date = new Date(e.drawTime);
      // // 获取对应的周几（0 表示周日，1 表示周一，以此类推）
      // var dayOfWeek = date.getDay();
      // // 将数字转换为对应的周几字符串
      // var weekdays = ['日', '一', '二', '三', '四', '五', '六'];
      // var dayString = weekdays[dayOfWeek];
      console.log(resDB);

      //数据信息
      resDB[0].numberList = resDB[0].resJKDb[0].number_attr.map((ele, i) => {
        return {
          num: ele.number, //号码
          shuxiang: ele.shengXiao, //属相
          wuxing: ele.wuXing, //五行
        }
      })
      let lot2 = res2.data.data[type - 1]
      let obj = resJKDb[0];
      // 使用 split 方法拆分字符串
      var parts = obj.lotteryTime.split(/-| /);
      console.log(parts);
      console.log(obj);
      console.log(lot2);
      obj.drawIssue = lot2.lotteryIssue //下一期
      obj.preDrawIssue = obj.issue; //当期
      obj.drawTime = lot2.lotteryTime + " " + lot2.time //开奖时间
      obj.nextPeriodMonth = parts[1]; //下一期月份
      obj.nextPeriodDay = parts[2]; //下一期日
      obj.nextPeriodWeek = lot2.lotteryWeek; //下一期周几
      obj.nextPeriodTime = obj.created_at; //下一期时间
      obj.date = obj.created_at
      // //其他信息
      // resDB[0].resJKDb=
      resDB[0].resJKDb = obj
      console.log(resDB);
      return resDB;
    }
  }

  static getIsShowTime(targetDateTimeString) {
    targetDateTimeString = this.formatTime(targetDateTimeString)
    // 指定的日期时间字符串

    // var targetDateTimeString = '2024-04-28 21:30:00';

    // 将日期时间字符串解析为 Date 对象
    var targetDateTime = new Date(targetDateTimeString).getTime();

    // 获取当前时间
    var now = new Date().getTime();
    // console.log(now >= targetDateTime);
    // 比较当前时间和指定时间
    if (now >= targetDateTime) {
      // console.log("当前时间早于指定时间---------未开奖------展示全部", now, targetDateTime);
      return true;
    } else {
      // console.log("当前时间已经到达或晚于指定时间---------开奖------定时展示全部", now, targetDateTime);
      return false;
    }
  }
  static formatTime(timeString) {
    if (!timeString) return ""
    timeString = timeString.replace("年", "-")
    timeString = timeString.replace("月", "-")
    timeString = timeString.replace("日", "")
    return timeString;
  }
  static getCurrentFormattedDate() {
    const date = new Date();
    const year = date.getFullYear(); // 获取四位年份
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 获取月份，月份从0开始计算，所以需要加1
    const day = date.getDate().toString().padStart(2, '0'); // 获取日期

    // 返回格式化的日期字符串 YYYY-MM-DD
    return `${year}-${month}-${day}`;
  }



  //获取开奖列表数据
  static async getkjlbData() {
    if (window.globalConfig.isLocalData) { //是否使用本地数据
      let res = await axios.get(`/data/common/开奖列表.json`);
      return res.data.data;
    } else { //使用接口数据
      // let res = await axios.get(`/apiUrl/localjson/开奖列表.json`, {
      //   headers: {
      //     'Cache-Control': 'no-cache, no-store, must-revalidate',
      //     'Pragma': 'no-cache',
      //     'Expires': '0'
      //   }
      // });
      // let res = await axios.post(`/apiUrl/api/Index/getJson?fileName=开奖列表`);
      // res.data = JSON.parse(res.data.data.fileValue)
      // return res.data.data;
      return [];
    }
  }
  //获取生肖属相数据
  static async getsxsxData() {
    if (window.globalConfig.isLocalData) { //是否使用本地数据
      let res = await axios.get(`/data/common/生肖属性.json`);
      return res.data.data;
    } else { //使用接口数据
      // let res = await axios.get(`/apiUrl/localjson/生肖属性.json`, {
      //   headers: {
      //     'Cache-Control': 'no-cache, no-store, must-revalidate',
      //     'Pragma': 'no-cache',
      //     'Expires': '0'
      //   }
      // });
      let res = await axios.post(`/apiUrl/api/Index/getJson?fileName=生肖属性`);
      res.data = JSON.parse(res.data.data.fileValue)
      return res.data.data;
    }
  }
  //获取波色数据
  static async getbsData() {
    if (window.globalConfig.isLocalData) { //是否使用本地数据
      let res = await axios.get(`/data/common/波色.json`);
      return res.data.data;
    } else { //使用接口数据
      // let res = await axios.get(`/apiUrl/localjson/波色.json`, {
      //   headers: {
      //     'Cache-Control': 'no-cache, no-store, must-revalidate',
      //     'Pragma': 'no-cache',
      //     'Expires': '0'
      //   }
      // });
      // return res.data.data;
      let res = await axios.post(`/apiUrl/api/Index/getJson?fileName=波色`);
      res.data = JSON.parse(res.data.data.fileValue)
      return res.data.data;
    }
  }
  //获取合数单双数据
  static async gethsdsData() {
    if (window.globalConfig.isLocalData) { //是否使用本地数据
      let res = await axios.get(`/data/common/合数单双.json`);
      return res.data.data;
    } else { //使用接口数据
      // let res = await axios.get(`/apiUrl/localjson/合数单双.json`, {
      //   headers: {
      //     'Cache-Control': 'no-cache, no-store, must-revalidate',
      //     'Pragma': 'no-cache',
      //     'Expires': '0'
      //   }
      // });
      // return res.data.data;
      let res = await axios.post(`/apiUrl/api/Index/getJson?fileName=合数单双`);
      res.data = JSON.parse(res.data.data.fileValue)
      return res.data.data;
    }
  }
  //获取五行对照数据
  static async getwxdzData() {
    if (window.globalConfig.isLocalData) { //是否使用本地数据
      let res = await axios.get(`/data/common/五行对照.json`);
      return res.data.data;
    } else { //使用接口数据
      // let res = await axios.get(`/apiUrl/localjson/五行对照.json`, {
      //   headers: {
      //     'Cache-Control': 'no-cache, no-store, must-revalidate',
      //     'Pragma': 'no-cache',
      //     'Expires': '0'
      //   }
      // });
      // return res.data.data;
      let res = await axios.post(`/apiUrl/api/Index/getJson?fileName=五行对照`);
      res.data = JSON.parse(res.data.data.fileValue)
      return res.data.data;
    }
  }
  //获取生肖对照数据
  static async getsxdzData() {
    if (window.globalConfig.isLocalData) { //是否使用本地数据
      let res = await axios.get(`/data/common/生肖对照.json`);
      return res.data.data;
    } else { //使用接口数据
      // let res = await axios.get(`/apiUrl/localjson/生肖对照.json`, {
      //   headers: {
      //     'Cache-Control': 'no-cache, no-store, must-revalidate',
      //     'Pragma': 'no-cache',
      //     'Expires': '0'
      //   }
      // });
      // return res.data.data;
      let res = await axios.post(`/apiUrl/api/Index/getJson?fileName=生肖对照`);
      res.data = JSON.parse(res.data.data.fileValue)
      return res.data.data;
    }
  }
  //获取富文本
  static async getHtmlObjData(params = {
    method: "select"
  }) {
    let res = await axios.post(`/apiUrl/api/Index/getAppConfig` + Util.getParams(params), {});
    console.log("获取所有富文本", res);
    return res.data.data;
  }
  //获取所有富文本
  static async getAllHtmlObjData(params = {
    method: "select"
  }) {
    let res = await axios.post(`/apiUrl/api/Index/getAppConfig` + Util.getParams(params), {});
    console.log("获取所有富文本", res);
    return res.data.data;
  }
  //获取图库
  static async getImgListData(params, p = {}) {
    let res = await axios.post(`/apiUrl/api/img_tab/List` + Util.getParams(params), p);
    console.log("获取图库", res);
    return res.data.data;
  }
  //获取页面数据=>分页
  static async getHtmlData(params, p = {}) {
    let res = await axios.post(`/apiUrl/api/img_tab/getComDbList` + Util.getParams(params), p);
    console.log("获取图库", res);
    return res.data.data;
  }
}